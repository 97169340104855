<template>
  <div />
</template>

<script>

export default {

  data() {
    return {
      user: {
        role: '',
      },
    }
  },

}
</script>

<style>
div#my-superset-container > iframe {
   width: 100%;
   height: 800px;
   border:0px;
 }
</style>
